<template>
  <div class="yeji-from-wrap">
    <div class="yeji-form">
      <div class="yj-label">
        方案名称：
      </div>
      <div class="yj-value ">
        <div v-if="detail.detail" class="fangan-name">
          {{ detail.detail.ruleName }}
        </div>
      </div>
    </div>

    <div class="yeji-form">
      <div class="yj-label">
        提成计算类型：
      </div>
      <div class="yj-value">
        <template v-if="detail.detail">
          <template v-if="detail.detail.calKind == 1">
            每单计算
          </template>
          <template v-if="detail.detail.calKind == 2">
            阶段式计算
          </template>
          <template v-if="detail.detail.calKind == 3">
            阶梯式计算
          </template>
        </template>
      </div>
    </div>

    <div class="yeji-form yeji-form-center">
      <div class="yj-label">
        关联角色：
      </div>
      <div class="yj-value">
        <el-checkbox-group v-model="ruleForm.relateiDs">
          <template v-if="ruleKind == 1 || ruleKind == 2">
            <template :key="item" v-for="item in shopList">
              <el-checkbox disabled :label="item.shopId">{{ item.shopName }}</el-checkbox>
            </template>
          </template>
          <template v-else>
            <template :key="item" v-for="item in roleList">
              <el-checkbox disabled :label="item.lvId">{{ item.levelName }}</el-checkbox>
            </template>
          </template>

        </el-checkbox-group>
      </div>
    </div>
    <div class="yeji-form">
      <div class="yj-label">
        消耗业绩：
      </div>
      <div class="yj-value xiaohao-yeji">
        <div class="xiaohao-yeji-mask"></div>
        <div v-if="ruleKind == 1 || ruleKind == 3" class="opt-checkbox">
          <div class="opt-title" v-if="detail.detail">单次消费</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.danci">
            <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button>
            <el-radio-button label="3">不计算
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="opt-checkbox">
          <div class="opt-title">储值卡-本金</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.chuzhikabenjin">
            <!-- <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button> -->
            <el-radio-button label="3">不计算
            </el-radio-button>
             <el-radio-button label="4">计算
            </el-radio-button>
          </el-radio-group>
          <div class="opt-title opt-title-btottom">储值卡-赠金</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.chuzhikazengsong">
            <!-- <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button> -->
            <el-radio-button label="3">不计算
            </el-radio-button>
             <el-radio-button label="4">计算
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="opt-checkbox">
          <div class="opt-title">账户余额-本金</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.yuebenjin">
            <!-- <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button> -->
            <el-radio-button label="3">不计算
            </el-radio-button>
             <el-radio-button label="4">计算
            </el-radio-button>
          </el-radio-group>
          <div class="opt-title opt-title-btottom">账户余额-赠金</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.yuezengsong">
            <!-- <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button> -->
            <el-radio-button label="3">不计算
            </el-radio-button>
            <el-radio-button label="4">计算
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="opt-checkbox">
          <div class="opt-title">有限次卡</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.youxianbenjin">
            <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button>
            <el-radio-button label="3">不计算
            </el-radio-button>
          </el-radio-group>
          <div class="opt-title opt-title-btottom">有限次卡-赠送</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.youxianzengsong">
            <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button>
            <el-radio-button label="3">不计算
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="opt-checkbox">
          <div class="opt-title">不限次卡</div>

          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.buxianbenjin">
            <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button>
            <el-radio-button label="3">不计算
            </el-radio-button>
          </el-radio-group>
          <div class="opt-title opt-title-btottom">不限次卡-赠送</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.buxianzengsong">
            <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button>
            <el-radio-button label="3">不计算
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="opt-checkbox">
          <div class="opt-title">定制卡</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.dingzhiben">
            <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button>
            <el-radio-button label="3">不计算
            </el-radio-button>
          </el-radio-group>
          <div class="opt-title opt-title-btottom">定制卡-赠送</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.dingzhizengsong">
            <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button>
            <el-radio-button label="3">不计算
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="opt-checkbox">
          <div class="opt-title">产品</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.shangpin">
            <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button>
            <el-radio-button label="3">不计算
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="opt-checkbox">
          <div class="opt-title">兑换券</div>
          <el-radio-group v-if="detail.detail" fill="#6D6D6D" size="small" v-model="detail.detail.duihuan">
            <el-radio-button label="1">原价
            </el-radio-button>
            <el-radio-button label="2">实收
            </el-radio-button>
            <el-radio-button label="3">不计算
            </el-radio-button>
          </el-radio-group>
        </div>

      </div>
    </div>

    <div class="yeji-form yeji-form-center">
      <div class="yj-label">
        多人分配：
      </div>
      <div class="yj-value duoren-fenpei">
        <!-- <el-radio-group v-if="detail.detail" disabled @change="handleSharetypeChange" v-model="detail.detail.sharetype">
          <el-radio :label="1">平均分配</el-radio>
          <el-radio @click.stop="handleShoudongSettingClick" :label="2">手动设置</el-radio>
        </el-radio-group> -->
        <div class="sharetype">
           <template v-if="detail.detail">
          <template v-if="detail.detail.sharetype == 1">
           平均分配
          </template>
          <template v-else>
           手动设置
          </template>
        </template>
        </div>

        <duorenFenpeiDialog @changeYejiSave="changeYejiSave" @yejiFormChange="yejiFormChange" v-show="isShowPnum">
        </duorenFenpeiDialog>
        <template v-if="detail.detail">
          <div v-show="detail.detail.sharetype == 2" class="pnum-title">
            <div>人数</div>
            <div>第1人</div>
            <div>第2人</div>
            <template v-if="String(detail.detail.personthree).trim().length > 0">
              <div>第3人</div>
            </template>
            <div v-if="detail.detail.personfour != null & String(detail.detail.personfour).trim().length > 0">第4人</div>
            <div v-if="String(detail.detail.personfive).trim().length > 0">第5人</div>
          </div>
          <div v-show="detail.detail.sharetype == 2" class="renshu-value">
            <template v-if="String(detail.detail.persontwo).length > 0">
              <div>2</div>
              <template :key="item" v-for="item in String(detail.detail.persontwo).split(',')">
                <div>
                  {{ item }}%
                </div>
              </template>
            </template>
            <template v-if="detail.detail.personthree != null && String(detail.detail.personthree).length > 0">
              <div>3</div>
              <template :key="item" v-for="item in String(detail.detail.personthree).split(',')">
                <div>
                  {{ item }}%
                </div>
              </template>
            </template>
            <template v-if="detail.detail.personfour != null && String(detail.detail.personfour).length > 0">
              <div>4</div>
              <template :key="item" v-for="item in String(detail.detail.personfour).split(',')">
                <div>
                  {{ item }}%
                </div>
              </template>
            </template>
            <template v-if="detail.detail.personfive != null && String(detail.detail.personfive).length > 0">
              <div>5</div>
              <template :key="item" v-for="item in String(detail.detail.personfive).split(',')">
                <div>
                  {{ item }}%
                </div>
              </template>
            </template>
          </div>
        </template>


      </div>
    </div>

  </div>
</template>

<script lang="js">
import emitter from '@/utils/eventbus'
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { COMPANY_ID } from '@/common/constant'
import duorenFenpeiDialog from './cpns/duoren-fenpei-dialog/duoren-fenpei-dialog.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { deepClone } from '@/utils/clone'
import lodash from 'lodash'
import { addRule } from '@/service/main/commission'
export default defineComponent({
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  components: {
    duorenFenpeiDialog
  },
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const ruleKind = computed(() => route.query.ruleKind)
    const ruleForm = reactive({
      ruleName: "",//规则名称
      companyId: "10000",//公司id
      calKind: '1',//计算方式1每笔计算2阶段计算3阶梯计算
      ruleKind: route.query.ruleKind,//规则分类1门店消耗2门店消费3角色消耗4角色消费
      danci: "",//单次消费:1原价2实收3不计算
      chuzhikabenjin: "",//储值卡本金
      chuzhikazengsong: "",//储值卡赠金
      yuebenjin: "",//余额本金
      yuezengsong: "",//余额赠送
      youxianbenjin: "",//有限次卡本金
      youxianzengsong: "",//有限次卡赠送
      buxianbenjin: "",//不限次卡本金
      buxianzengsong: "",//不限次卡赠送
      dingzhiben: "",//定制卡本金
      dingzhizengsong: "",//定制卡赠送
      shangpin: "",//商品
      duihuan: "",//兑换券
      sharetype: "",//分配规则1平均分配personXXX参数不传值，2手动分配，根据人数传值
      persontwo: "",//
      personthree: "",
      personfour: "",
      personfive: "",
      relateiDs: []//关联门店或角色id
    })

    watch(() => props.detail, (n) => {
      ruleForm.relateiDs = []
      if (n != null) {
        n.bindItems.forEach(item => {
          // console.log(item);
          ruleForm.relateiDs.push(item.relateId)
        })

      }
    }, {
      deep: true
    })

    const relateiDs = ref([])

    const isShowPnum = ref(false)
    const roleList = computed(() => store.state.commission.roleList)

    const handleNextClick = async () => {
      let obj = deepClone(ruleForm)
      // console.log(obj);
      if (Array.isArray(obj.relateiDs)) {
        obj.relateiDs = obj.relateiDs.join()
      }

      if (obj.sharetype == 2) {
        let ids = []
        yejiForm.value.forEach(item => {
          ids.push(item.value)
        })

        if (yejiForm.value.length == 2) {
          obj.persontwo = ids.join()
        } else if (yejiForm.value.length == 3) {
          obj.personthree = ids.join()
        } else if (yejiForm.value.length == 4) {
          obj.personfour = ids.join()
        } else if (yejiForm.value.length == 5) {
          obj.personfive = ids.join()
        }
      }

      const res = await addRule(obj)
      if (res.code == 0) {
        emit('changeNextClick', {
          calKind: ruleForm.calKind,
          rid: res.data
        })
      }
      // emit('changeNextClick', {
      //   calKind: ruleForm.calKind,
      //   rid: ''
      // })

    }

    const handleCountKindChange = (value) => {
      emit('countKindChange', value)
      // console.log(value);
    }

    const handleSharetypeChange = (value) => {
      if (value == 2) {
        isShowPnum.value = true
      } else {
        isShowPnum.value = false
      }
      // console.log(value);
    }

    const radio2 = ref('')
    const handleyejiClick = () => {
      isShowPnum.value = false

    }

    const handleShoudongSettingClick = () => {
      // console.log(123);
      isShowPnum.value = true
    }

    const yejiForm = ref([
      { value: '' },
      { value: '' },

    ])

    const yejiFormChange = (value) => {
      yejiForm.value = value
    }

    const changeYejiSave = () => {
      isShowPnum.value = false
    }

    const shopList = computed(() => store.state.reserve.shopList)

    const initPage = () => {
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
      store.dispatch('commission/getRoleListAction')
    }
    initPage()

    return {
      shopList,
      ruleKind,
      changeYejiSave,
      yejiForm,
      yejiFormChange,
      handleShoudongSettingClick,
      handleyejiClick,
      radio2,
      ruleForm,
      handleNextClick,
      handleCountKindChange,
      COMPANY_ID,
      isShowPnum,
      handleSharetypeChange,
      roleList,
      relateiDs
    }

  }
})
</script>

<style scoped lang="less">
.yeji-from-wrap {
  // min-height: 900px;
  margin-bottom: 20px;

  &:deep(.el-radio-button__original-radio:checked+.el-radio-button__inner) {
    background-color: rgb(109, 109, 109) !important;
    border-color: rgb(109, 109, 109) !important;
    box-shadow: none !important;
  }

  .fangan-name {
    &:deep(.el-input) {
      max-width: 262px;
    }
  }

  .yeji-form-center {
    align-items: center;
  }



  .yeji-form {
    display: flex;
    margin-bottom: 27px;
    font-size: 14px;

    .yj-label {
      width: 120px;
      font-weight: bold;
      color: rgba(80, 80, 80, 1);

    }

    .duoren-fenpei {
      // padding: 0 10px;
      .sharetype{
        margin: 10px 0;
        font-size: 12px;
      }
      .renshu-value {
        display: flex;
        width: 455px;
        padding: 0 10px;

        &>div {
          flex: 1;
          line-height: 36px;
          font-size: 12px;
          color: rgba(80, 80, 80, 1);

        }
      }

      .pnum-title {
        display: flex;
        width: 455px;
        padding: 0 10px;
        background-color: rgba(231, 231, 231, 1);
        font-size: 12px;

        &>div {
          flex: 1;
          line-height: 36px;
          font-size: 12px;
          color: rgba(80, 80, 80, 1);

        }
      }
    }

    .yj-value {
      flex: 1;
      position: relative;

      .dr-fenpei {
        position: absolute;
      }
    }

    .save-from {
      display: flex;
      align-items: center;
      margin-top: 64px;

      &:deep(.el-button) {
        width: 213px;
        height: 48px;
      }

      .tips {
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        margin-left: 19px;
      }
    }

    .xiaohao-yeji {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      .xiaohao-yeji-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        z-index: 100;
      }

      .opt-checkbox {
        border-radius: 5px;
        font-size: 14px;
        width: 240px;
        padding: 14px;
        min-height: 146px;
        border: 1px solid rgb(204, 204, 204, 1);
        margin-bottom: 12px;
        margin-right: 12px;

        .opt-title {
          margin-bottom: 11px;

        }

        .opt-title-btottom {
          margin-top: 11px;
        }
      }
    }
  }
}
</style>




