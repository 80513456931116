<template>
  <div class="ticheng-form-wrap">
    <div class="yeji-form ">
      <div class="yj-label">
        提成规则配置：
      </div>
      <div class="yj-value peizhi-value">
        <div class="titles">
          <div class="num">序号</div>
          <div>品类</div>
          <div class="price">提成类型</div>
          <div class="price">提成金额</div>
          <div>操作</div>
        </div>

        <div class="peizhi-list">
          <template v-if="detail && detail.catas.length > 0">
            <template :key="item"
              v-for="(item, index) in detail.detail.ruleKind == 1 || detail.detail.ruleKind == 3 ? serveCategoryList : cardCategoryList">
              <div class="item">
                <div class="num">{{ index + 1 }}</div>
                <div>{{ item.kindName }}</div>
                <div class="price">
                  <template v-if="detail.catas[index]">
                    {{ detail.catas[index].archkind == 1 ? '固定' : '百分比' }}
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div class="price">
                  <template v-if="detail.catas[index]">
                    {{ detail.catas[index].qty }}{{ detail.catas[index].archkind == 1 ? '元' : '%' }}
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div>
                  <el-button @click="handleProductSetting(item.kindId)" size="small" plain>特殊产品设置
                    ({{ (detail.specServes.filter(ele => ele.cataId == item.kindId)).length
                    }})
                  </el-button>
                </div>
              </div>
            </template>
          </template>

        </div>
      </div>
    </div>

    <template v-if="detail.detail">
      <div v-if="detail.detail.calKind == 2" class="yeji-form ">
        <div class="yj-label">
          阶段区间配置：
        </div>
        <div class="yj-value jd-peizhi-value">
          <div class="titles">
            <div class="num">序号</div>
            <div class="qujian">业绩区间</div>
            <div class="type">提成类型</div>
            <div class="price">提成金额</div>
            <!-- <div class="opt"></div> -->
          </div>

          <div class="peizhi-list">
            <template :key="item" v-for="(item, index) in detail.archRange">
              <jieduanForm :index="index" :item="item"></jieduanForm>
            </template>
          </div>
        </div>
      </div>

      <div v-if="detail.detail.calKind == 3" class="yeji-form ">
        <div class="yj-label">
          阶梯式区间配置：
        </div>
        <div class="yj-value jd-peizhi-value">
          <div class="titles">
            <div class="num">序号</div>
            <div class="qujian">业绩区间</div>
            <div class="type">提成类型</div>
            <div class="price">提成金额</div>
            <!-- <div class="opt"></div> -->
          </div>

          <div class="peizhi-list">
            <template :key="item" v-for="(item, index) in detail.archRange">
              <jietiForm :isLast="index == detail.archRange.length - 1" :index="index" :item="item"></jietiForm>
            </template>
          </div>
        </div>
      </div>
    </template>




    <distribute :tesuForm='tesuForm' :detailRes="ruleDetail" v-if="dialogVisible" @changeRuleForm='changeRuleForm'
      :cid="currentCid" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible"></distribute>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import { tichengType } from './config'
import jieduanForm from './cpns/jieduan-form/jieduan-form'
import jietiForm from './cpns/jieti-form/jieti-form.vue'
import distribute from './cpns/distribute-dialog/distribute-dialog.vue'
import { useRoute } from 'vue-router'
import { getServeCategoryList, addRuleSaveRule, addRuleSaveJieDuanRule, getRuleDetail } from '@/service/main/commission'
import { COMPANY_ID } from '@/common/constant'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'



export default defineComponent({
  props: {
    countType: {
      type: Number,
      required: true
    },
    ruleId: {
      type: Number,
      default: -1
    },
    detail: {
      type: Object,
      required: true
    }
  },
  components: {
    jieduanForm,
    jietiForm,
    distribute
  },
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()

    const jiduanListFormList = ref([])
    const jiduanListForm = ref([{
      yjstart: '',
      yjend: '',
      tctype: '',
      tcprice: '',
    }])

    const handelAddClick = () => {
      jiduanListForm.value.push({
        yjstart: '',
        yjend: '',
        tctype: '',
        tcprice: '',
      })
    }

    const cardCategoryList = ref([
      {
        kindName: '有限次卡',
        kindId: 3
      }, {
        kindName: '不限次卡',
        kindId: 4
      },
      {
        kindName: '储值卡',
        kindId: 5
      }
    ])


    const jietiForm = ref([
      {
        yjstart: '',
        yjend: '',
        tctype: '',
        tcprice: '',
      },
      {
        yjstart: '',
        yjend: '',
        tctype: '',
        tcprice: '',
      }
    ])

    const rulePeizhiForm = ref([])

    const handelRemoveClick = (index) => {
      jiduanListForm.value.splice(index, 1)
    }

    const jietiRemoveClick = (index) => {
      jietiForm.value.splice(index, 1)
    }

    const handlePreviousClick = () => {
      emit('previousClick')
    }

    const jietiAddClick = () => {
      jietiForm.value.push({
        yjstart: '',
        yjend: '',
        tctype: '',
        tcprice: '',
        ruleId: '',
        cataStr: '',
        specStr: '',
        ruleKind: route.query.ruleKind == 1 || route.query.ruleKind == 3 ? 1 : 2
      })
    }


    const guizePeizhiFormList = ref([])
    const tesuFormList = ref([])
    const jietiFormList = ref([])
    const handleSaveClick = async () => {
      guizePeizhiFormList.value = []
      tesuFormList.value = []
      jiduanListFormList.value = []
      jietiFormList.value = []
      guizePeizhiForm.value.forEach(item => {
        let flag = true
        for (let key in item) {
          if (String(item[key]).length == 0) {
            flag = false
            break;
          }
        }
        if (flag) {
          guizePeizhiFormList.value.push(item)
        }
      })

      tesuForm.value.forEach(item => {
        let flag = true
        for (let key in item) {
          if (String(item[key]).length == 0) {
            flag = false
            break;
          }
        }
        if (flag) {
          tesuFormList.value.push(item)
        }
      })

      jiduanListForm.value.forEach(item => {
        let flag = true
        for (let key in item) {
          if (String(item[key]).length == 0) {
            flag = false
            break;
          }
        }
        if (flag) {
          jiduanListFormList.value.push(item)
        }
      })

      if (jietiForm.value.length > 2) {
        jietiForm.value.forEach(item => {
          let flag = true
          for (let key in item) {
            if (String(item[key]).length == 0) {
              flag = false
              break;
            }
          }
          if (flag) {
            jietiFormList.value.push(item)
          }
        })
      } else {
        jietiFormList.value = jietiForm.value
      }

      let obj = {
        ruleId: props.ruleId,
        cataStr: [],
        specStr: [],
        ruleKind: route.query.ruleKind == 1 || route.query.ruleKind == 3 ? 1 : 2
      }
      guizePeizhiFormList.value.forEach(item => {
        obj.cataStr.push(`${item.cid},${item.tctype},${item.tcprice}`)
      })

      tesuFormList.value.forEach(item => {
        obj.specStr.push(`${item.cid},${item.serveId},${item.tctype},${item.tcprice}`)
      })

      obj.cataStr = obj.cataStr.join('&')
      obj.specStr = obj.specStr.join('&')
      // console.log(obj);
      const res = await addRuleSaveRule(obj)
      let flag = true
      if (res.code != 0) {
        flag = false
      }

      if (props.countType == 2) {
        let obj = {
          ruleId: props.ruleId,
          companyId: COMPANY_ID,
          rangeStr: [],
          calKind: 1
        }
        jiduanListFormList.value.forEach(item => {
          obj.rangeStr.push(`${item.yjstart},${item.yjend},${item.tctype},${item.tcprice}`)
        })
        obj.rangeStr = obj.rangeStr.join('&')
        const res = await addRuleSaveJieDuanRule(obj)
        if (res.code != 0) {
          flag = false
        }
      }

      if (props.countType == 3) {
        // console.log(1231312);
        let obj = {
          ruleId: props.ruleId,
          companyId: COMPANY_ID,
          rangeStr: [],
          calKind: 2
        }

        jietiFormList.value[0].yjstart = 0
        jietiFormList.value[(jietiFormList.value.length) - 1].yjend = 0
        jietiFormList.value.forEach(item => {
          obj.rangeStr.push(`${item.yjstart},${item.yjend},${item.tctype},${item.tcprice}`)
        })
        obj.rangeStr = obj.rangeStr.join('&')
        const res = await addRuleSaveJieDuanRule(obj)
        if (res.code != 0) {
          flag = false
        }
      }


      if (flag) {
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        router.push('/main/commission/list')
      } else {
        ElMessage.error('操作失败,请刷新')
      }
    }





    const dialogVisible = ref(false)
    const changeDialogVisible = (flag) => {
      dialogVisible.value = flag
    }


    const currentCid = ref('')
    const handleProductSetting = (cid) => {
      // console.log(cid);
      currentCid.value = cid
      dialogVisible.value = true
    }

    const ruleDetail = ref('')
    const serveCategoryList = ref([])
    const guizePeizhiForm = ref([])
    const initPage = async () => {
      const res = await getServeCategoryList({
        companyId: COMPANY_ID
      })
      guizePeizhiForm.value = []
      serveCategoryList.value = res.data.serveCata
      const detailRes = await getRuleDetail(COMPANY_ID, route.query.ruleId)
      ruleDetail.value = detailRes.data
    }
    initPage()


    const tesuForm = ref([])
    const changeRuleForm = (list) => {
      list.forEach(item => {
        const index = tesuForm.value.findIndex(ele => ele.serveId == item.serveId)

        if (index == -1) {
          tesuForm.value.push(item)
        }
      })

    }


    return {
      ruleDetail,
      cardCategoryList,
      jietiFormList,
      jiduanListFormList,
      tesuFormList,
      guizePeizhiFormList,
      tesuForm,
      changeRuleForm,
      currentCid,
      dialogVisible,
      changeDialogVisible,
      handleProductSetting,
      jietiRemoveClick,
      jietiAddClick,
      handlePreviousClick,
      tichengType,
      jiduanListForm,
      handelAddClick,
      handelRemoveClick,
      jietiForm,
      serveCategoryList,
      guizePeizhiForm,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
.ticheng-form-wrap {
  padding-bottom: 82px;

  .yeji-form-center {
    align-items: center;
  }



  .yeji-form {
    display: flex;
    margin-bottom: 27px;
    font-size: 14px;

    .yj-label {
      width: 120px;
      font-weight: bold;
      color: rgba(80, 80, 80, 1);

    }

    .yj-value {
      flex: 1;

    }

    .jd-peizhi-value {
      max-width: 700px;
      font-size: 12px;

      .titles {
        display: flex;
        height: 36px;
        line-height: 36px;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(239, 239, 239, 1);
        font-size: 12px;
        padding: 0 18px;

        &>div {
          flex: 1;
        }
      }
    }

    .peizhi-value {
      max-width: 700px;

      &:deep(.el-select) {
        max-width: 120px;
      }

      &:deep(.el-input) {
        max-width: 120px;
      }

      .peizhi-list {


        .item {
          display: flex;
          line-height: 60px;
          height: 60px;
          border-bottom: 1px solid rgba(204, 204, 204, .6);
          padding: 0 18px;
          font-size: 12px;

          .num {
            flex: .6;
          }

          &>div {
            flex: 1;
          }

          .price {
            flex: 1.4;
          }
        }
      }

      .titles {
        display: flex;
        height: 36px;
        line-height: 36px;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(239, 239, 239, 1);
        font-size: 12px;
        padding: 0 18px;

        .num {
          flex: .6;
        }

        .price {
          flex: 1.4;
        }

        &>div {
          flex: 1;
        }
      }
    }

    .save-from {
      display: flex;
      align-items: center;
      margin-top: 64px;

      &:deep(.el-button) {
        width: 213px;
        height: 48px;
      }

      .tips {
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        margin-left: 19px;
      }
    }


  }
}
</style>




