export const breadcrumbList = [
  {
    name: '规格列表',
    path: ''
  },
  {
    name: '规则详情',
    path: ''
  }
]
