<template>
  <div class="jieduan-form">
    <div class="num">{{ index + 1 }}</div>
    <div class="qujian">
      {{ item.rangemin }}
      <span>-</span>
      {{ item.rangemax }}
    </div>
    <div class="type">
      <template v-if="item.kind == 1">
        固定
      </template>
      <template v-else>
        百分比
      </template>
    </div>
    <div class="price">
        {{ item.qty }}{{item.kind == 1?'元':'%'}}
    </div>
    <!-- <div class="opt">

    </div> -->
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { tichengType } from '../../config'
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  components: {

  },
  setup(props, { emit }) {

    const handelAddClick = () => {
      emit('handelAddClick')
    }

    const handelRemoveClick = (index) => {
      emit('handelRemoveClick', index)
    }

    return {
      tichengType,
      handelAddClick,
      handelRemoveClick
    }

  }
})
</script>

<style scoped lang="less">
.jieduan-form {
  display: flex;
  max-width: 700px;
  line-height: 60px;
  height: 60px;
  border-bottom: 1px solid rgba(204, 204, 204, .6);
  padding: 0 18px;



  &:deep(.el-select) {
    max-width: 120px;
  }

  &:deep(.el-input) {
    max-width: 120px;
  }




&>div{
  flex: 1;
}

}
</style>




