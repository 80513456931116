<template>
  <div class="commission-detail">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="commission-detail-content">
      <yejiForm :detail="ruleDetail"></yejiForm>
      <tichengForm :detail="ruleDetail"></tichengForm>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { breadcrumbList } from './config'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { useStore } from 'vuex'
import { getRuleDetail } from '@/service/main/commission'
import { COMPANY_ID } from '@/common/constant'
import yejiForm from './cpns/yeji-form/yeji-form'
import tichengForm from './cpns/ticheng-form/ticheng-form'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    yejiForm,
    tichengForm
  },
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const ruleDetail = ref('')
    const initPage = async () => {
      const res = await getRuleDetail(COMPANY_ID, route.query.ruleId)
      // console.log(res);
      ruleDetail.value = res.data
    }
    initPage()

    return {
      ruleDetail,
      breadcrumbList,
      COMPANY_ID
    }

  }
})
</script>

<style scoped lang="less">
.commission-detail {
  min-width: 980px;

  .commission-detail-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;

  }
}
</style>




